import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import userRoutes from '@/router/user'
import docRoutes from '@/router/doc'

const routes: Array<RouteRecordRaw> = [
  ...userRoutes,
  ...docRoutes,
  {
    path: '/:catchAll(.*)',
    component: () => import('../views/abnormal/errorF04.vue'),
    meta: { Title: '404', judge: false }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
