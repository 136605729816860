import { createStore } from 'vuex'
import CounterModule from '@/store/CounterModule'
import UserModule, { USER_NAME } from '@/store/user'
import MenuModule, { MENU_NAME } from '@/store/menu'
import { getModule } from 'vuex-module-decorators'
import { namespace } from 'vuex-class'



const store = createStore({
  modules: {
    counter: CounterModule,
    user: UserModule,
    menu: MenuModule,
  }
})
export default store







export const menuModule = getModule(MenuModule, store)

export const menuStore = namespace(MENU_NAME)

// export const userModule = getModule(UserModule, store)
// export const userStore = namespace(USER_NAME)
