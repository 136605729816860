import { RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    redirect: "/qa/en",
    // component: LoginView
  },
  {
    path: "/feedback",
    name: "feedback",
    children: [
      {
        path: "en",
        meta: { title: 'Question Feedback', lang: 'en' },
        component: () =>
          import(/* webpackChunkName: "doc" */ "../views/Feedback.vue"),
      },
      {
        path: "cn",
        meta: { title: '问题反馈', lang: 'cn' },
        component: () =>
          import(/* webpackChunkName: "doc" */ "../views/Feedback.vue"),
      },
    ],
  },

];

export default routes;
