import { RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/giftcard",
    name: "giftcard",
    children: [
      {
        path: "en",
        meta: { title: 'How to Get App Activation Code', lang: 'en' },
        component: () =>
          import(/* webpackChunkName: "doc" */ "../views/GiftCard.vue"),
      },
      {
        path: "cn",
        meta: { title: '如何获取APP激活码', lang: 'cn' },
        component: () =>
          import(/* webpackChunkName: "doc" */ "../views/GiftCard.vue"),
      },
    ],
  },
  {
    path: "/qa",
    name: "question",
    children: [
      {
        path: "cn",
        meta: { tab: 'qa' },
        component: () =>
          // import(/* webpackChunkName: "doc" */ "../views/Question.vue"),
          import(/* webpackChunkName: "doc" */ "../views/ClassVideo.vue"),
      },
      {
        path: "en",
        meta: { tab: 'qa' },
        component: () =>
          import(/* webpackChunkName: "doc" */ "../views/ClassVideoEn.vue"),
      },
    ],
  },
  {
    path: "/agree",
    name: "agree",
    children: [
      {
        path: "cn",
        component: () =>
          import(/* webpackChunkName: "doc" */ "../views/Agreement.vue"),
      },
      {
        path: "en",
        component: () =>
          import(/* webpackChunkName: "doc" */ "../views/AgreementEn.vue"),
      },
    ],
  },

  {
    path: "/cancel",
    name: "cancel",
    children: [
      {
        path: "cn",
        component: () =>
          import(/* webpackChunkName: "doc" */ "../views/CancelView.vue"),
      },
      {
        path: "en",
        component: () =>
          import(/* webpackChunkName: "doc" */ "../views/CancelEnView.vue"),
      },
    ],
  },
  {
    path: "/third",
    name: "third",
    children: [
      {
        path: "cn",
        component: () =>
          import(/* webpackChunkName: "doc" */ "../views/ThirdPart.vue"),
      },
      {
        path: "en",
        component: () =>
          import(/* webpackChunkName: "doc" */ "../views/ThirdPartEn.vue"),
      },
    ],
  },
  {
    path: "/permission",
    name: "permission",
    children: [
      {
        path: "ios/cn",
        component: () =>
          import(/* webpackChunkName: "doc" */ "../views/PermissionIos.vue"),
      },
      {
        path: "ios/en",
        component: () =>
          import(/* webpackChunkName: "doc" */ "../views/PermissionIosEn.vue"),
      },
      {
        path: "android/cn",
        component: () =>
          import(
            /* webpackChunkName: "doc" */ "../views/PermissionAndroid.vue"
          ),
      },
      {
        path: "android/en",
        component: () =>
          import(
            /* webpackChunkName: "doc" */ "../views/PermissionAndroidEn.vue"
          ),
      },
    ],
  },

  {
    path: "/privacy",
    name: "privacy",
    children: [
      {
        path: "cn",
        component: () =>
          import(/* webpackChunkName: "doc" */ "../views/PrivacyView.vue"),
      },
      {
        path: "en",
        component: () =>
          import(/* webpackChunkName: "doc" */ "../views/PrivacyEnView.vue"),
      },
    ],
  },

  {
    path: "/download",
    name: "download",
    children: [
      {
        path: "",
        component: () =>
          import(/* webpackChunkName: "doc" */ "../views/DownloadEn.vue"),
      },
      {
        path: "en",
        component: () =>
          import(/* webpackChunkName: "doc" */ "../views/DownloadEn.vue"),
      },
      {
        path: "cn",
        component: () =>
          import(/* webpackChunkName: "doc" */ "../views/Download.vue"),
      },
    ],
  },
  {
    path: "/classvideo",
    name: "classvideo",
    children: [
      {
        path: "en",
        meta: { tab: 'video', title: ' FAQ' },
        component: () =>
          import(/* webpackChunkName: "doc" */ "../views/ClassVideoEn.vue"),
      },
      {
        path: "cn",
        meta: { tab: 'video', title: '常见问题' },
        component: () =>
          import(/* webpackChunkName: "doc" */ "../views/ClassVideo.vue"),
      },
    ],
  },
  {
    path: "/app",
    name: "app",
    children: [
      {
        path: "en",
        component: () =>
          import(/* webpackChunkName: "doc" */ "../views/AppVersionEn.vue"),
      },
      {
        path: "cn",
        component: () =>
          import(/* webpackChunkName: "doc" */ "../views/AppVersion.vue"),
      },
    ],
  },
  {
    path: "/version",
    name: "version",
    children: [
      {
        path: "en",
        component: () =>
          import(/* webpackChunkName: "doc" */ "../views/VersionEnView.vue"),
      },
      {
        path: "cn",
        component: () =>
          import(/* webpackChunkName: "doc" */ "../views/VersionView.vue"),
      },
    ],
  },
];

export default routes;
