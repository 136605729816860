import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { Lazyload } from 'vant';
import 'vant/lib/index.css';

const app = createApp(App);
app.use(Lazyload, {
    preLoad: 1,//默认1.3,1的时候只加载自身，1.3提前加载后面0.3高度的图片
    attempt: 2,
}).use(store).use(router).mount("#app");
